import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

const OTPInput = ({ value, onChange, onVerify, loading, autoFocus }) => {
  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));

  useEffect(() => {
    if (autoFocus) {
      inputRefs.current[0].current.focus();
    }
  }, [autoFocus]);

  const handleChange = (index, e) => {
    const newValue = e.target.value;
    if (newValue.length <= 1 && /^[0-9]*$/.test(newValue)) {
      const newOTP = [...value];
      newOTP[index] = newValue;
      onChange(newOTP.join(''));
      if (newValue.length === 1 && index < 5) {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, 6);
    if (/^[0-9]{1,6}$/.test(pastedData)) {
      onChange(pastedData.padEnd(6, ' '));
      inputRefs.current[pastedData.length - 1].current.focus();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex items-center justify-between gap-2"
    >
      <div className="flex gap-1">
        {[...Array(6)].map((_, index) => (
          <motion.div
            key={index}
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ delay: index * 0.05 }}
          >
            <input
              ref={inputRefs.current[index]}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={value[index] || ""}
              autoFocus={autoFocus && index === 0}
              className="w-7 h-6  text-center text-[11px] font-medium 
                       bg-gray-50 text-gray-900 rounded-md
                       border border-gray-200 
                       focus:border-gray-900 focus:ring-1 focus:ring-gray-500/20
                       transition-all duration-200 mx-0.5"
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={handlePaste}
            />
          </motion.div>
        ))}
      </div>
      <button
        onClick={onVerify}
        disabled={value.length !== 6 || loading}
        className="px-3 py-1 text-[10px] font-medium text-white bg-gray-900 hover:bg-gray-800 rounded-md transition-colors disabled:opacity-50"
      >
        Verify
      </button>
    </motion.div>
  );
};

export default OTPInput;

// import React, { useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';

// const OTPInput = ({ value, onChange, onVerify, loading, autoFocus }) => {
//   const inputRefs = useRef([...Array(6)].map(() => React.createRef()));

//   useEffect(() => {
//     if (autoFocus) {
//       inputRefs.current[0].current.focus();
//     }
//   }, [autoFocus]);

//   const handleChange = (index, e) => {
//     const newValue = e.target.value;
//     if (newValue.length <= 1 && /^[0-9]*$/.test(newValue)) {
//       const newOTP = [...value];
//       newOTP[index] = newValue;
//       onChange(newOTP.join(''));
//       if (newValue.length === 1 && index < 5) {
//         inputRefs.current[index + 1].current.focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, e) => {
//     if (e.key === 'Backspace' && !value[index] && index > 0) {
//       inputRefs.current[index - 1].current.focus();
//     }
//   };

//   const handlePaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text/plain').slice(0, 6);
//     if (/^[0-9]{1,6}$/.test(pastedData)) {
//       onChange(pastedData.padEnd(6, ' '));
//       inputRefs.current[pastedData.length - 1].current.focus();
//     }
//   };

//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 5 }}
//       animate={{ opacity: 1, y: 0 }}
//       className="flex items-center justify-between gap-3"
//     >
//       <div className="flex gap-1.5">
//         {[...Array(6)].map((_, index) => (
//           <motion.div
//             key={index}
//             initial={{ scale: 0.8 }}
//             animate={{ scale: 1 }}
//             transition={{ delay: index * 0.05 }}
//           >
//             <input
//               ref={inputRefs.current[index]}
//               type="text"
//               inputMode="numeric"
//               maxLength={1}
//               value={value[index] || ""}
//               autoFocus={autoFocus && index === 0}
//               className="w-8 h-8 sm:w-10 sm:h-10 text-center text-[13px] sm:text-[14px] font-medium 
//                        bg-gray-50 text-gray-900 rounded-md
//                        border border-gray-200 
//                        focus:border-gray-900 focus:ring-1 focus:ring-gray-500/20
//                        transition-all duration-200"
//               onChange={(e) => handleChange(index, e)}
//               onKeyDown={(e) => handleKeyDown(index, e)}
//               onPaste={handlePaste}
//             />
//           </motion.div>
//         ))}
//       </div>
//       <button
//         onClick={onVerify}
//         disabled={value.length !== 6 || loading}
//         className="px-4 py-1.5 sm:py-2 text-[13px] font-medium text-white bg-gray-900 hover:bg-gray-800 rounded-md transition-colors disabled:opacity-50"
//       >
//         Verify
//       </button>
//     </motion.div>
//   );
// };

// export default OTPInput;

